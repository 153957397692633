import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, CardActionArea, Divider } from "@mui/material";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

export default function MediaCard(props) {
  const { video, title, description, Link, to, alt } = props;
  return (
    <Card sx={{ maxWidth: 400, width: "100%", marginX: "10px" }}>
      <CardActionArea>
        <CardMedia
          component="video"
          height="230px"
          image={video}
          alt={alt}
          autoPlay
          loop
          muted
        />
        <Box sx={{}}>
          <Divider
            orientation="horizontal"
            sx={{
              border: "solid 2px #0249ae",
              backgroundColor: "#0249ae",
              width: "100%",
            }}
          />
        </Box>
        <CardContent sx={{}}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ marginLeft: "-10px" }}>
              <ArrowRightIcon fontSize="large" />
            </Box>
            <Box>
              <Typography
                gutterBottom
                variant="p"
                component="div"
                sx={{
                  fontWeight: "500",
                  textAlign: "left",
                  fontSize: "1.5rem",
                  color: "#000",
                }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
          <Divider />

          <Box sx={{ textAlign: "left", paddingTop: "10px" }}>
            <Typography
              variant="p"
              sx={{
                fontWeight: "500",
                textAlign: "left",
                color: "#000",
                fontSize: "15px",
              }}
            >
              {description}
            </Typography>
          </Box>
        </CardContent>
        <Box
          sx={{ textAlign: "center", fontSize: "15px", paddingBottom: "0px" }}
        >
          <NavLink to={to} style={{ textDecoration: "none", color: "#0249ae" }}>
            <Button
              variant="text"
              sx={{
                width: "100%",
                textTransform: "capitalize",
                color: "#0249ae",
                fontSize: "17px",
                fontFamily: "sans-serif",
                fontWeight: "200",
                backgroundColor: "#fff",
                "&:hover": {
                  color: "#000",
                },
              }}
            >
              {Link}
              <ArrowCircleRightIcon
                sx={{ fontSize: "22px", paddingLeft: "5px" }}
              />
            </Button>
          </NavLink>
        </Box>
      </CardActionArea>
    </Card>
  );
}
