import { Box, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";
import LandingPhoto from "../../assets/loader.png";
import { NavLink } from "react-router-dom";
import Social from "../social-share-box/Social";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import Contact from "../Contact/Contact";
import logo from "../../assets/cedg.png";

const Footer = () => {
  const socialBtn = [
    {
      id: "1",
      title: "Facebook",
      to: "/",
      icon: <FacebookOutlinedIcon />,
    },
  ];
  const BlogLinks = [
    {
      id: "1",
      title: "Exploring Mining Frontiers: Tales from the World Below",
      to: "/",
      date: "May 30, 2023 No Comments",
    },
    {
      id: "1",
      title: "Exploring Mining Frontiers: Tales from the World Below",
      to: "/",
      date: "May 30, 2023 No Comments",
    },
  ];
  const footerMenu = [
    { id: "1", title: "Privacy & Policy", to: "/" },
    { id: "2", title: "Terms & Conditions", to: "/" },
    { id: "3", title: "Disclaimer", to: "/" },
    { id: "5", title: "FAQ", to: "/" },
  ];
  const links = [
    { id: "1", title: "About Us", to: "/about" },
    { id: "2", title: "Services", to: "/cedg-services/petroleum-services" },
  ];
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          paddingTop: "5%",
          paddingBottom: "50px",
        }}
      >
        <Box sx={{ backgroundColor: "#404040", marginTop: "-5%" }}>
          <Grid container lg={12}>
            <Grid item lg={6} xs={12}>
              <Box>
                <Box
                  sx={{
                    textAlign: "left",
                    paddingLeft: "8%",
                    display: "flex",
                    paddingTop: "10%",
                  }}
                >
                  <Box sx={{ paddingRight: "15px" }}>
                    <Divider
                      orientation="vertical"
                      width="11px"
                      sx={{
                        border: "solid 2px #0249ae",
                        backgroundColor: "#0249ae",
                        height: "20vh",
                      }}
                    />
                  </Box>
                  <Box>
                    <Box>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: { xl: "60px", lg: "50px", xs: "30px" },
                          fontWeight: "700",
                          lineHeight: "1.1em",
                          color: "#fff",
                        }}
                      >
                        Join Us in Shaping the
                        <br /> Future of Mining!
                      </Typography>
                    </Box>
                    <Box sx={{ paddingTop: "5%" }}>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "20px",
                          fontWeight: "400",
                          color: "#fff",
                        }}
                      >
                        Contact with us!
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Box
                sx={{
                  backgroundColor: "#0249ae",
                  width: "90%",
                  height: { lg: "65vh", xs: "0vh" },
                  marginLeft: { lg: "40%", xs: "0%" },
                }}
              />
            </Grid>
            <Grid item lg={3}>
              <Box
                sx={{
                  paddingY: { xl: "5%", lg: "", xs: "5%" },
                  marginTop: "10%",
                  marginLeft: { lg: "-80%", xs: "10%" },
                  marginRight: { lg: "60%", xs: "0" },
                }}
              >
                <Contact />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: "4%" }}>
          <Grid container lg={12}>
            <Grid item lg={3} xs={12}>
              <Box sx={{ marginX: "5%" }}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                >
                  <Box
                    component="img"
                    src={logo}
                    alt="cedg"
                    sx={{ width: "50%", marginY: "20px" }}
                  />{" "}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
              {" "}
              <Box
                sx={{
                  textAlign: { lg: "left", xl: "left", xs: "center" },
                  paddingLeft: { lg: "5%", xs: "10%" },
                  paddingRight: { lg: "0%", xs: "10%" },
                  paddingTop: "20px",
                }}
              >
                <Typography
                  variant="p"
                  fontWeight="500"
                  sx={{ color: "#161616" }}
                >
                  CHINA ENERGY DEVELOPMENT GROUP (CEDG) was established in year
                  2019 in Singapore, strategically positioned as a regional
                  powerhouse in the marketing and distribution of
                  high-performance energy commodities and value-added specialty
                  products.
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={3} xs={12}>
              <Box sx={{ marginTop: { xs: "20px" } }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#0249ae",
                  }}
                >
                  Quick Links
                </Typography>
              </Box>
              <Box sx={{ marginLeft: "40%", marginTop: "20px" }}>
                {links.map((item, index) => (
                  <Box sx={{ marginY: "-25px" }}>
                    <List key={index} sx={{ "&:hover": { color: "#0249ae" } }}>
                      <NavLink to={item.to} style={{ textDecoration: "none" }}>
                        <ListItem>
                          <Typography
                            sx={{
                              color: "#161616",
                              "&:hover": { color: "#0249ae" },
                              fontWeight: "700",
                            }}
                          >
                            {item.title}
                          </Typography>
                        </ListItem>
                      </NavLink>
                    </List>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: "60px", textAlign: "center" }}>
          <Divider sx={{ backgroundColor: "#161616" }} />
        </Box>
        <Grid container lg={12} xs={12} sx={{ marginBottom: "-20px" }}>
          <Grid
            item
            xl={12}
            lg={6}
            xs={12}
            sx={{
              textAlign: { lg: "center", xs: "center" },
              paddingLeft: "6%",
              marginTop: "25px",
              paddingX: { xs: "25%", lg: "0" },
            }}
          >
            <Box sx={{ color: "#161616" }}>
              Copyright 2023 © All Right Reserved Design by MD
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
