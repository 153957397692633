import React from "react";
import SEO from "../../hooks/SEO";
import Hero from "../../components/Hero/Hero";
import About from "../../components/about-section/About";
import Services from "../../components/service-section/Services";
import KServices from "../../components/Kind-of-services/KServices";
import HWW from "../../components/how-we-work/HWW";
import Partner from "../../components/Partners/Partner";
const Landing = () => {
  return (
    <>
      <SEO
        title="CEDG EGYPT FOR TRADING CO"
        description="CEDG EGYPT FOR TRADING CO"
        name="CEDG EGYPT."
        type="landing_page"
      />
      <Hero />
      <About />
      <Services />
      <HWW />
      <Partner />
      <KServices />
      {/*<WhatWeHave />*/}
      {/*<Blog />*/}
    </>
  );
};

export default Landing;
