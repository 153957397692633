import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import GoldMine from "../../assets/Gold-Mine.png";
import AboutSection from "../../components/about-section/About";
import Team from "../../components/Team/Team";
import Heading from "../../components/Heading/Heading";
import SEO from "../../hooks/SEO";
import PISection from "../../components/Section/PISection";
import SPISection from "../../components/Section/SPISection ";
import CSection from "../../components/CSection/CSection";
import team from "../../assets/business-team.jpg";
import comp from "../../assets/composition.jpg";
import direct from "../../assets/business-handshake.jpg";

const About = () => {
  return (
    <>
      {" "}
      <SEO
        title="CEDG Company | INTERNATIONAL FOR Petrolum Oiling"
        description="CEDG Company International For Petrolum Oiling Landing Page."
        name="CEDG Company"
        type="landing_page"
      />
      <Box sx={{ backgroundColor: "#161616", textAlign: "center" }}>
        <Box sx={{}}>
          <Box>
            <Heading head="About Us" img={GoldMine} />
          </Box>
        </Box>
        <Box>
          <AboutSection />
        </Box>

        <Box>
          <PISection
            img={team}
            Fhead="Our"
            Lhead="Business"
            desc="CEDG excels in securing volume allocations through offtakes from reputable Middle East suppliers,
offering diverse specifications and ample volumes tailored to meet and surpass our customers' shortand
long-term needs."
          />
          <SPISection
            Simg={comp}
            SFhead="Our"
            SLhead="Strength"
            Sdesc="Our strength lies in our well-established connections with industry stakeholders, regionally and
globally. This empowers CEDG with the acumen to craft adaptable short- to long-term agreements
with both suppliers and customers. Our commitment to excellence compels us to perpetually enhance
our global market presence in terms of volume and quality, accentuated by the introduction of new
products from key producers."
          />
          <PISection
            img={direct}
            Fhead="Our"
            Lhead="Clients"
            desc="CEDG maintains direct relationships with international state-owned enterprises and publicly listed
companies, characterized by mutually beneficial short- to long-term volume and price contracts. Our
unrelenting commitment is to continuously elevate our standing in the international market, both in
terms of volume and quality."
          />
        </Box>
        <Box sx={{ paddingY: "10%" }}>
          <CSection />
        </Box>
        <Box>
          {/*          <Team />
           */}{" "}
        </Box>
      </Box>
    </>
  );
};

export default About;
