import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Logo1 from "../../assets/Logo_InPixio-2.png";
import Logo2 from "../../assets/IMEX-Logo.svg";
import Logo3 from "../../assets/logo.gif";
import Logo4 from "../../assets/cedg.jpg";

const Partner = () => {
  const data = [
    { id: "1", icon: Logo1 },
    { id: "2", icon: Logo2 },
    { id: "3", icon: Logo3 },
  ];
  return (
    <>
      <Box
        sx={{
          paddingLeft: { lg: "0%", md: "2%", xs: "10%" },
          paddingRight: { md: "2%", xs: "0", lg: "0", xl: "0" },
          textAlign: "left",
          paddingY: "5%",
        }}
      >
        <Box>
          <Grid container lg={12} md={12} xs={12}>
            <Grid item xl={6} lg={6} md={12} xs={12}>
              <Box
                sx={{
                  textAlign: "left",
                  marginLeft: { xl: "10%", lg: "10%", md: "2%", xs: "0%" },
                  display: "flex",
                  paddingTop: { lg: "5%", md: "7%", xs: "10%" },
                }}
              >
                <Box sx={{ paddingRight: "15px" }}>
                  <Divider
                    orientation="vertical"
                    sx={{
                      width: "11px",
                      border: "solid 2px #0249ae",
                      backgroundColor: "#0249ae",
                      height: { lg: "15vh", md: "22vh", xs: "10vh" },
                    }}
                  />
                </Box>
                <Box>
                  <Box>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: { lg: "20px", md: "20px", xs: "23px" },
                        fontWeight: "400",
                      }}
                    ></Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: {
                          xl: "50px",
                          lg: "50px",
                          md: "80px",
                          xs: "2.3rem",
                        },
                        fontWeight: "700",
                        lineHeight: "1.1em",
                      }}
                    >
                      A rising tide
                      <br /> lifts all boats.
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        paddingTop: "50px",
                        paddingBottom: "20px",
                        paddingRight: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "0%",
                          xs: "5%",
                        },
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{ fontSize: "20px", fontWeight: "400" }}
                      >
                        Our esteemed supplier partners are industry leaders,
                        renowned for their proficiency in gasoil refining and
                        the extraction of phosphates rock and minerals in Egypt.
                        The synergy between our suppliers' manufacturing
                        excellence and top-tier products and CEDG's extensive
                        international expertise in commodities trade secures our
                        collaborative endeavor to expand our reach into global
                        markets.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={12}
              xs={12}
              sx={{
                paddingTop: { xl: "2%", lg: "2%", xs: "5%" },
                display: { xs: "flex", xl: "flex", lg: "flex", md: "flex" },
              }}
            >
              <Box
                sx={{
                  display: { lg: "flex", md: "flex", xs: "flex" },
                  justifyContent: "center",
                  justifyItems: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {data.map((item) => (
                  <Grid item xl={6} lg={8} md={6} xs={12}>
                    <Box
                      sx={{
                        position: "relative",
                        marginTop: { lg: "30%", xs: "10%" },
                        textAlign: "center",
                        marginX: { lg: "10%", xs: "15%" },
                        marginRight: { lg: "", xs: "20%" },
                      }}
                    >
                      <Box
                        component="img"
                        src={item.icon}
                        sx={{
                          width: { lg: "110%", xs: "100%" },
                          padding: "20px",
                        }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Partner;
