import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

const PISection = (props) => {
  const { Simg, SFhead, SLhead, Sdesc } = props;
  return (
    <div>
      {/* Second Perspective  */}
      <Box>
        {" "}
        <Grid container lg={12} sx={{ marginLeft: "30px", marginTop: "5%" }}>
          <Grid item lg={6}>
            {" "}
            <Box
              sx={{
                textAlign: "left",
                marginLeft: {
                  xl: "10%",
                  lg: "10%",
                  md: "2%",
                  xs: "0%",
                },
                display: "flex",
                paddingTop: { lg: "5%", md: "7%", xs: "10%" },
              }}
            >
              <Box sx={{ paddingRight: "15px" }}>
                <Divider
                  orientation="vertical"
                  width="11px"
                  sx={{
                    border: "solid 2px #0249ae",
                    backgroundColor: "#0249ae",
                    height: { lg: "17vh", md: "22vh", xs: "17vh" },
                  }}
                />
              </Box>
              <Box>
                <Box>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: {
                        xl: "50px",
                        lg: "50px",
                        md: "80px",
                        xs: "3.6rem",
                      },
                      fontWeight: "700",
                      lineHeight: "1.1em",
                      color: "#fff",
                    }}
                  >
                    {SFhead} <br />
                    {SLhead}
                  </Typography>
                </Box>
                <Box>
                  <Box
                    sx={{
                      paddingTop: "50px",
                      paddingBottom: "20px",
                      marginRight: {
                        xl: "20%",
                        lg: "0%",
                        md: "0%",
                        sm: "0%",
                        xs: "10%",
                      },
                      marginLeft: {
                        xl: "0%",
                        lg: "0%",
                        md: "0%",
                        sm: "0%",
                        xs: "-5%",
                      },
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#fff",
                      }}
                    >
                      {Sdesc}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={5} sx={{ marginX: "20px" }}>
            {" "}
            <Box
              sx={{
                marginTop: "20px",
                marginLeft: { xl: "0px", lg: "35px" },
                marginRight: { xl: "00px" },
              }}
            >
              <Box
                component="img"
                alt="Image"
                src={Simg}
                sx={{
                  width: { lg: "80%", xs: "80%" },
                  marginRight: { lg: "0px", xs: "10%" },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default PISection;
