import styled from "@emotion/styled";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ServicesDropDown } from "../../data/mockData";
const CustomNav = styled("div")({
  textDecoration: "none",
  color: "#fff",
  fontFamily:
    "wfont_e9f997_c6ade2174a214d059c3d2c1784c22f5a,wf_c6ade2174a214d059c3d2c178,orig_jubilat_regular",
  "&:hover": {
    cursor: "pointer",
    color: "#0249ae",
    backgroundColor: "#fff",
    fontWeight: "bold",
    borderRadius: "20px",
    paddingLeft: "7px",
    paddingRight: "7px",
  },
});
const NavMenu = () => {
  const [anchorMenu, setAnchorMenu] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            paddingX: { lg: "35%", md: "10%", xs: "0%" },
            paddingLeft: { xl: "10%", lg: "15%", md: "0%", xs: "20%" },
          }}
        >
          <Box sx={{ paddingX: "2%" }}>
            <NavLink style={{ textDecoration: "none" }} to={"/"}>
              <CustomNav>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Home
                </Typography>
              </CustomNav>
            </NavLink>
          </Box>
          <Box sx={{ paddingX: "5%" }}>
            <NavLink
              style={{ textDecoration: "none" }}
              id="Company"
              to="/about"
            >
              <Box sx={{ display: "flex" }}>
                <Box>
                  <CustomNav>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      About
                    </Typography>
                  </CustomNav>
                </Box>
              </Box>
            </NavLink>
          </Box>
          <Box sx={{ paddingX: "5%" }}>
            <NavLink
              style={{ textDecoration: "none" }}
              id="Services"
              to="/cedg-services/petroleum-services"
            >
              <Box sx={{ display: "flex" }}>
                <Box>
                  <CustomNav>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      Services
                    </Typography>
                  </CustomNav>
                </Box>
              </Box>
            </NavLink>
          </Box>
          {/*<Box sx={{ paddingX: "2%" }}>
            <NavLink
              style={{ textDecoration: "none" }}
              id="menu-2"
              onClick={handleOpenMenu}
              open={handleOpenMenu}
            >
              <Box sx={{ display: "flex" }}>
                <Box>
                  <CustomNav>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      Services
                    </Typography>
                  </CustomNav>
                </Box>
                <Box sx={{ paddingLeft: "10px", paddingTop: "2px" }}>
                  <Typography
                    variant="h7"
                    fontSize="20px"
                    fontWeight="bold"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "#0249ae",
                      },
                    }}
                  >
                    +
                  </Typography>
                </Box>
              </Box>
            </NavLink>

            <Menu
              id="menu-2"
              anchorMenu={anchorMenu}
              open={Boolean(anchorMenu)}
              onClose={handleCloseMenu}
              sx={{
                mt: { xl: "9%", lg: "9%", md: "", xs: "20%" },
                ml: { xl: "-65%", lg: "", md: "", xs: "-40%" },
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {ServicesDropDown.map((item) => (
                <NavLink
                  to={`/${item.link}`}
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem
                    key={item.id}
                    onClick={handleCloseMenu}
                    sx={{
                      transition: "0  .3s linear",
                      justifyContent: "stretch",
                      alignItems: "center",
                      borderBottom: "1px solid rgba(0, 0, 0, .1)",
                      zIndex: 40,
                      paddingX: "35px",
                      paddingY: "15px",
                      backdropFilter: "blur(4px)",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#ED7D31",
                      },
                    }}
                  >
                    <Typography variant="h7" color="#000">
                      {item.title}
                    </Typography>
                  </MenuItem>
                </NavLink>
              ))}
            </Menu>
          </Box>
*/}
          {/*<Box sx={{ paddingX: "2%" }}>
            <NavLink style={{ textDecoration: "none" }} to={"/contact-us"}>
              <CustomNav>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Contact Us
                </Typography>
              </CustomNav>
            </NavLink>
          </Box>*/}
        </Box>
      </Box>
    </>
  );
};

export default NavMenu;
