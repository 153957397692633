import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import MediaCard from "../info-card/MediaCard";
import { MediaData } from "../../data/mockData";
const KServices = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#161616",
          paddingBottom: "0%",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            paddingTop: "0%",
          }}
        >
          {" "}
          <Box
            sx={{
              paddingLeft: { lg: "0%", md: "2%", xs: "10%" },
              paddingRight: { md: "2%", xs: "0", lg: "0", xl: "0" },
              textAlign: "left",
              paddingY: "5%",
            }}
          >
            <Box>
              <Grid
                container
                lg={12}
                md={12}
                xs={12}
                sx={{ display: { lg: "flex", xs: "none" } }}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={12}
                  xs={12}
                  sx={{
                    paddingTop: { xl: "2%", lg: "2%", xs: "20%" },
                    display: { xs: "flex", xl: "flex", lg: "flex", md: "flex" },
                    marginLeft: { xl: "10%", lg: "2%", xs: "20%" },
                    marginRight: { xl: "-10%", lg: "2%", xs: "20%" },
                  }}
                >
                  <Box
                    sx={{
                      display: { lg: "flex", md: "flex", xs: "flex" },
                      justifyContent: "center",
                      justifyItems: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {MediaData.map((item) => (
                      <Box key={item.id} sx={{}}>
                        <Grid item>
                          <MediaCard
                            video={item.video}
                            title={item.title}
                            description={item.description}
                            to={item.to}
                            Link={item.link}
                          />
                        </Grid>
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={12} xs={12}>
                  <Box
                    sx={{
                      textAlign: "left",
                      marginLeft: { xl: "0%", lg: "10%", md: "2%", xs: "0%" },
                      display: "flex",
                      paddingTop: { lg: "5%", md: "7%", xs: "10%" },
                    }}
                  >
                    <Box sx={{ paddingRight: "15px" }}>
                      <Divider
                        orientation="vertical"
                        sx={{
                          width: "11px",
                          border: "solid 2px #0249ae",
                          backgroundColor: "#0249ae",
                          height: { lg: "15vh", md: "22vh", xs: "10vh" },
                        }}
                      />
                    </Box>
                    <Box>
                      <Box>
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: { lg: "20px", md: "20px", xs: "23px" },
                            fontWeight: "400",
                          }}
                        ></Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: {
                              xl: "50px",
                              lg: "50px",
                              md: "80px",
                              xs: "2.3rem",
                            },
                            color: "#fff",
                            fontWeight: "700",
                            lineHeight: "1.1em",
                          }}
                        >
                          Mining Excellence,
                          <br /> Shaping Industries
                        </Typography>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            paddingTop: "50px",
                            paddingBottom: "20px",
                            paddingRight: {
                              xl: "32%",
                              lg: "0%",
                              md: "0%",
                              sm: "0%",
                              xs: "5%",
                            },
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "400",
                            }}
                          >
                            The mining industry has a unique culture and
                            history, from mining towns to songs about the
                            struggles of miners. Mining is not just a job, it's
                            a way of life that requires dedication, skill, and
                            courage.{" "}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                lg={12}
                md={12}
                xs={12}
                sx={{ display: { lg: "none", xs: "flex" } }}
              >
                <Grid item xl={6} lg={6} md={12} xs={12}>
                  <Box
                    sx={{
                      textAlign: "left",
                      marginLeft: { xl: "0%", lg: "10%", md: "2%", xs: "0%" },
                      display: "flex",
                      paddingTop: { lg: "5%", md: "7%", xs: "10%" },
                    }}
                  >
                    <Box sx={{ paddingRight: "15px" }}>
                      <Divider
                        orientation="vertical"
                        sx={{
                          width: "11px",
                          border: "solid 2px #0249ae",
                          backgroundColor: "#0249ae",
                          height: { lg: "15vh", md: "22vh", xs: "10vh" },
                        }}
                      />
                    </Box>
                    <Box>
                      <Box>
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: { lg: "20px", md: "20px", xs: "23px" },
                            fontWeight: "400",
                          }}
                        ></Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: {
                              xl: "50px",
                              lg: "50px",
                              md: "80px",
                              xs: "2.3rem",
                            },
                            color: "#fff",
                            fontWeight: "700",
                            lineHeight: "1.1em",
                          }}
                        >
                          Mining Excellence,
                          <br /> Shaping Industries
                        </Typography>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            paddingTop: "50px",
                            paddingBottom: "20px",
                            paddingRight: {
                              xl: "32%",
                              lg: "0%",
                              md: "0%",
                              sm: "0%",
                              xs: "5%",
                            },
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "400",
                            }}
                          >
                            The mining industry has a unique culture and
                            history, from mining towns to songs about the
                            struggles of miners. Mining is not just a job, it's
                            a way of life that requires dedication, skill, and
                            courage.{" "}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={12}
                  xs={12}
                  sx={{
                    paddingTop: { xl: "2%", lg: "2%", xs: "20%" },
                    display: { xs: "flex", xl: "flex", lg: "flex", md: "flex" },
                    marginLeft: { xl: "10%", lg: "2%", xs: "0%" },
                    marginRight: { xl: "-10%", lg: "2%", xs: "10%" },
                  }}
                >
                  <Box
                    sx={{
                      display: { lg: "flex", md: "flex", xs: "flex" },
                      justifyContent: "center",
                      justifyItems: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {MediaData.map((item) => (
                      <Box key={item.id} sx={{}}>
                        <Grid item>
                          <MediaCard
                            video={item.video}
                            title={item.title}
                            description={item.description}
                            to={item.to}
                            Link={item.link}
                          />
                        </Grid>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/*<Box sx={{ marginTop: "5%" }}>
            <NavLink to="/heard-services">
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: "#fff",
                  fontSize: "17px",
                  border: "solid 1px #ED7D31 ",
                  fontFamily: "sans-serif",
                  fontWeight: "200",
                  "&:hover": {
                    color: "#ED7D31",
                    border: "solid 1px #fff",
                  },
                }}
              >
                All Services
              </Button>
            </NavLink>
          </Box>*/}
        </Box>
      </Box>
    </>
  );
};

export default KServices;
