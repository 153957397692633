import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { Header } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { Landing } from "./pages/index";
import About from "./pages/About/About";
import Blog from "./pages/Blog/Blog";
import PostDetails from "./pages/Blog/PostDetails";
import Services from "./pages/Services/Services";
import Contact from "./pages/Contact/Contact";
import Mining from "./pages/Services/IndService/Mining";
import Petrol from "./pages/Services/IndService/Petrol";
import Trade from "./pages/Services/IndService/Trade";

const Router = () => {
  return (
    <>
      <Header />
      <Routes>
        {/* Landing-Page */}
        <Route path="/" exact element={<Landing />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/team" exact element="Team-Page" />
        {/* Services-Pages */}
        <Route path="/cedg-services" element={<Services />} />
        <Route path="/cedg-services/petroleum-services" element={<Petrol />} />

        {/* Blog-Pages */}
        <Route path="/cedg-articals" element={<Blog />} />
        <Route path="/cedg-articals/:id" element={<PostDetails />} />
        {/* Blog-Pages */}
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/cedg-articals/:id" element="Single-Post" />
        {/* Error-Pages */}
        <Route path="/404" element="Page404" />
        <Route path="*" element=<Navigate to="/404" replace /> />
      </Routes>
      <Footer />
    </>
  );
};

export default Router;
