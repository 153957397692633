import engineer from "../assets/engineer.jpg";
import strip from "../assets/Strip.mp4";
import Petroleum from "../assets/Petroleum.mp4";
import trade from "../assets/trade/trade.mp4";
import MiningIndust from "../assets/mining-indust.webp";
import indust from "../assets/indust.jpg";
import refinery from "../assets/refinery.jpg";
import OilPlatform from "../assets/oil-platform.jpg";
import vlc from "../assets/vlc.png";
import freight from "../assets/freight.jpg";
import Trade from "../assets/trade.png";
export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    phone: "(665)121-5454",
    access: "admin",
  },
];

export const posts = [
  {
    id: 1,
    title: "Exploring Mining Frontiers: Tales from the World Below",
    short:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dolor lectus, tempus vitae tempus dapibus, lobortis sit amet augue. Donec venenatis ante non orci dignissim",
    long:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dolor lectus, tempus vitae tempus dapibus, lobortis sit amet augue. Donec venenatis ante non orci dignissim blandit. Donec interdum dui risus, quis ultricies felis blandit vel. Suspendisse commodo semper arcu, vitae bibendum neque cursus feugiat. Suspendisse id dolor iaculis turpis iaculis efficitur. Fusce venenatis mollis sagittis. Quisque hendrerit lacus eget porta blandit. Nulla scelerisque ipsum et blandit auctor. Ut et risus in enim fermentum cursus.Suspendisse potenti. Sed dictum ut magna eu laoreet. Nunc vulputate dignissim maximus. Sed accumsan, dui eu varius pretium, enim justo facilisis ligula, id aliquam erat velit non felis. Morbi sed augue mollis, vestibulum nunc a, feugiat ligula. Ut nunc lacus, sodales ac ex nec, feugiat scelerisque velit",
    link: "/",
    cover: engineer,
  },
  {
    id: 2,
    title: "Exploring Mining Frontiers: Tales from the World Below",
    short:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dolor lectus, tempus vitae tempus dapibus, lobortis sit amet augue. Donec venenatis ante non orci dignissim",
    long:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dolor lectus, tempus vitae tempus dapibus, lobortis sit amet augue. Donec venenatis ante non orci dignissim blandit. Donec interdum dui risus, quis ultricies felis blandit vel. Suspendisse commodo semper arcu, vitae bibendum neque cursus feugiat. Suspendisse id dolor iaculis turpis iaculis efficitur. Fusce venenatis mollis sagittis. Quisque hendrerit lacus eget porta blandit. Nulla scelerisque ipsum et blandit auctor. Ut et risus in enim fermentum cursus.Suspendisse potenti. Sed dictum ut magna eu laoreet. Nunc vulputate dignissim maximus. Sed accumsan, dui eu varius pretium, enim justo facilisis ligula, id aliquam erat velit non felis. Morbi sed augue mollis, vestibulum nunc a, feugiat ligula. Ut nunc lacus, sodales ac ex nec, feugiat scelerisque velit",
    link: "/",
    cover: engineer,
  },
  {
    id: 3,
    title: "Exploring Mining Frontiers: Tales from the World Below",
    short:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dolor lectus, tempus vitae tempus dapibus, lobortis sit amet augue. Donec venenatis ante non orci dignissim",
    long:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dolor lectus, tempus vitae tempus dapibus, lobortis sit amet augue. Donec venenatis ante non orci dignissim blandit. Donec interdum dui risus, quis ultricies felis blandit vel. Suspendisse commodo semper arcu, vitae bibendum neque cursus feugiat. Suspendisse id dolor iaculis turpis iaculis efficitur. Fusce venenatis mollis sagittis. Quisque hendrerit lacus eget porta blandit. Nulla scelerisque ipsum et blandit auctor. Ut et risus in enim fermentum cursus.Suspendisse potenti. Sed dictum ut magna eu laoreet. Nunc vulputate dignissim maximus. Sed accumsan, dui eu varius pretium, enim justo facilisis ligula, id aliquam erat velit non felis. Morbi sed augue mollis, vestibulum nunc a, feugiat ligula. Ut nunc lacus, sodales ac ex nec, feugiat scelerisque velit",
    link: "/",
    cover: engineer,
  },
  {
    id: 4,
    title: "Exploring Mining Frontiers: Tales from the World Below",
    short:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dolor lectus, tempus vitae tempus dapibus, lobortis sit amet augue. Donec venenatis ante non orci dignissim",
    long:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dolor lectus, tempus vitae tempus dapibus, lobortis sit amet augue. Donec venenatis ante non orci dignissim blandit. Donec interdum dui risus, quis ultricies felis blandit vel. Suspendisse commodo semper arcu, vitae bibendum neque cursus feugiat. Suspendisse id dolor iaculis turpis iaculis efficitur. Fusce venenatis mollis sagittis. Quisque hendrerit lacus eget porta blandit. Nulla scelerisque ipsum et blandit auctor. Ut et risus in enim fermentum cursus.Suspendisse potenti. Sed dictum ut magna eu laoreet. Nunc vulputate dignissim maximus. Sed accumsan, dui eu varius pretium, enim justo facilisis ligula, id aliquam erat velit non felis. Morbi sed augue mollis, vestibulum nunc a, feugiat ligula. Ut nunc lacus, sodales ac ex nec, feugiat scelerisque velit",
    link: "/",
    cover: engineer,
  },
];
export const ServicesDropDown = [
  { id: 1, title: "Mines & Mining", link: "heard-services/mines-&-mining" },
  {
    id: 1,
    title: "Petroleum Services",
    link: "heard-services/petroleum-services",
  },
  {
    id: 1,
    title: "International Trade",
    link: "heard-services/international-trade",
  },
];
export const CompanyDropDown = [{ id: 1, title: "About", link: "about" }];

export const ServiceData = [
  {
    id: 1,
    service: "Diesel Gasoil and Phosphates",
    description:
      "CEDG specializes in delivering exceptional quality Diesel Gasoil and Phosphates, directly sourced from the reputable producers.",
    link: "",
    icon: "",
  },
  {
    id: 2,
    service: "International Trading",
    description:
      "international trade can improve livelihoods, reduce poverty, and foster innovation worldwide",
    link: "",
    icon: "",
  },
];

export const MediaData = [
  {
    id: 2,
    title: "Petroleum Services",
    description:
      "Petroleum refineries(refine) crude oil into petroleum products for use as fuels and generating electricity .",
    video: Petroleum,
    link: "Read More",
    to: "cedg-services/petroleum-services",
  },
];

export const MiningData = {
  id: 1,
  header: "Mines and Mining",
  MainImg: MiningIndust,
  title: "What is the Mining Industry and Why is it Important?",
  first:
    "Mining is the process of extracting minerals from a seam, vein, reef, lode, or placer deposit in the earth. Coal, metals, oil shale, rock salt, potash, gemstones, limestone, clay, chalk, stone, and gravel are all extracted in mining.",
  second:
    "There are many types of mining: surface mining and underground mining. Surface mining is much more common than underground mining.",
  subtitle: "Types of Mining",
  last:
    "The mining industry accounts for prospecting, planning and permitting the mine, extraction of raw minerals via surface mining and/or underground mining techniques, processing and mineral dressing, and the restoration or reclamation of the mine at the end of its economic life cycle.",
  img: indust,
  listItem: "Surface Mining",
  listItem2: "Underground Mining",
  listItem3: "Artisinal Mining",
  types: [
    {
      id: 1.1,
      title: "Surface Mining",
      Type: [
        { title: "Strip mining" },
        { title: "Open pit mining" },
        { title: "Quarrying mining" },
        { title: "Mountaintop removal mining" },
        { title: "Highwall mining" },
        { title: "Placer mining" },
        { title: "In-situ leach (ISL) mining" },
      ],
    },
    {
      id: 1.2,
      title: "Underground Mining",
      Type: [
        { title: "Room and pillar mining" },
        { title: "Retreat mining" },
        { title: "Shrinkage stope mining" },
        { title: "Sublevel open mining" },
        { title: "Cut and fill mining" },
        { title: "Sublevel mining" },
        { title: "Block mining" },
        { title: "Longwall mining" },
        { title: "Drift and fill mining" },
      ],
    },
    {
      id: 1.3,
      title: "Artisinal Mining",
      Type: [
        { title: "Seasonal artisanal miners" },
        { title: "Rush-type artisanal miners" },
        { title: "Shock-push artisanal miners" },
        { title: "Permanent artisanal miners" },
      ],
    },
  ],
};
export const PetrolData = {
  id: 1,
  header: "Petroleum Services",
  MainImg: OilPlatform,
  title: "What is the Petroleum Refining Industry and Why is it Important?",
  first:
    "Petroleum refineries convert (refine) crude oil into petroleum products for use as fuels for transportation, heating, paving roads, and generating electricity and as feedstocks for making chemicals.",
  second:
    "Refining breaks crude oil down into its various components, which are then selectively reconfigured into new products. Petroleum refineries are complex and expensive industrial facilities. All refineries have three basic steps:",
  subtitle: "Basic Steps of Petroleum Refining",
  last:
    "Both incoming crude oil and the outgoing final products are stored temporarily in large tanks on a tank farm near the refinery. Pipelines, trains, and trucks carry the final products from the storage tanks to locations across the country",
  img: vlc,
  listItem: "Separation",
  listItem2: "Conversion",
  listItem3: "Treatment",
};
export const TradeData = {
  id: 1,
  header: "Internatoinal Trade",
  MainImg: freight,
  title: "What is the International Trading and Why is it Important?",
  first:
    "Foreign Trade is executed by the State or Government and International Trade is exercised by companies and/or individuals and/or institutions. Therefore, when we study the Foreign Trade of a country, we are studying its rules, norms, laws and its commercial policy. And when we speak of International Trade, we are referring to exports and imports of goods, services and capital.",
  subtitle: "Types of International Trade",
  second:
    "While it can be challenging to navigate communication differences across languages and cultures, there are tools and resources available to make international trade easier and more efficient than ever before.",
  last:
    "Knowing the basic definitions of foreign trade can help us understand and do international business more clearly and securely.",
  img: Trade,
  listItem: "Export Trade",
  listItem2: "Import Trade",
  listItem3: "Entrepot Trade",
};
