import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import InfoServiceCard from "../info-card/InfoService";
import refinery from "../../assets/refinery.png";
import MDList from "../List/MDList";
import { ServiceData } from "../../data/mockData";

const Services = () => {
  const listData = [
    {
      id: 1,
      title:
        "Develop and implement mining and international trade strategies to achieve the company's objectives.",
    },
    {
      id: 2,
      title:
        " Research and exploration of new mining sites and assess the financial, environmental and economic aspects.",
    },
  ];

  return (
    <>
      <Box
        sx={{
          paddingLeft: { lg: "0%", md: "2%", xs: "10%" },
          paddingRight: { md: "2%", lg: "0", xs: "0" },
          textAlign: "left",
          paddingY: "10%",
        }}
      >
        <Box>
          <Grid container lg={12} md={12} xs={12}>
            <Grid item xl={6} lg={6} md={12} xs={12}>
              <Box
                sx={{
                  textAlign: "left",
                  marginLeft: { xl: "10%", lg: "10%", md: "2%", xs: "0%" },
                  display: "flex",
                  paddingTop: { lg: "5%", md: "7%", xs: "10%" },
                }}
              >
                <Box sx={{ paddingRight: "15px" }}>
                  <Divider
                    orientation="vertical"
                    width="11px"
                    sx={{
                      border: "solid 2px #0249ae",
                      backgroundColor: "#0249ae",
                      height: { lg: "17vh", md: "22vh", xs: "25vh" },
                    }}
                  />
                </Box>
                <Box>
                  <Box>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: { lg: "20px", md: "20px", xs: "23px" },
                        fontWeight: "400",
                        color: "#0249ae",
                      }}
                    >
                      Services
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: {
                          xl: "50px",
                          lg: "50px",
                          md: "80px",
                          xs: "3.6rem",
                        },
                        fontWeight: "700",
                        lineHeight: "1.1em",
                      }}
                    >
                      Uncover
                      <br /> hidden value.{" "}
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        paddingTop: "50px",
                        paddingBottom: "20px",
                        marginRight: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "0%",
                          xs: "5%",
                        },
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "0%",
                          xs: "-10%",
                        },
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{ fontSize: "20px", fontWeight: "400" }}
                      >
                        Our team of experts specialize in unlocking the true
                        potential of mining and trade investments. Don't leave
                        value on the table.
                      </Typography>
                    </Box>
                    {listData.map((item) => (
                      <Box
                        key={item.id}
                        sx={{
                          marginLeft: {
                            xl: "0%",
                            lg: "0%",
                            md: "0%",
                            sm: "0%",
                            xs: "-10%",
                          },
                        }}
                      >
                        <MDList title={item.title} />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={12}
              xs={12}
              sx={{
                paddingTop: "2%",
                display: { xs: "none", xl: "flex", lg: "flex", md: "flex" },
              }}
            >
              <Box sx={{ display: { lg: "flex", md: "flex", xs: "block" } }}>
                <Grid container lg={12} md={12} xs={12}>
                  {ServiceData.map((item) => (
                    <Grid item xl={6} lg={6} md={6} xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          position: "relative",
                          rotate: "-10deg",
                          marginTop: "-25%",
                          zIndex: 4,
                          "&:hover": { rotate: "0deg", zIndex: 10 },
                        }}
                        key={item.id}
                      >
                        <InfoServiceCard
                          title={item.service}
                          description={item.description}
                          icon={refinery}
                          link={item.link}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Services;
