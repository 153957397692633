import { Avatar, Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import glenn from "../../assets/Glenn.png";
import cedg from "../../assets/CEDG logo .jpg";
import PISection from "../Section/PISection";
import mail from "../../assets/mail.png";
import phone from "../../assets/telephone.png";
import fax from "../../assets/fax.png";
import location from "../../assets/location.png";
const CSection = () => {
  return (
    <div>
      {" "}
      <Paper
        sx={{
          alignContent: "center",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          justifyItems: "center",

          backgroundColor: "#404040",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Grid
            container
            spacing={2}
            lg={12}
            sx={{
              alignContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid item lg={6} sx={{ marginLeft: "", marginRight: "" }}>
              {" "}
              <Box
                sx={{
                  textAlign: "left",
                  marginLeft: {
                    xl: "10%",
                    lg: "10%",
                    md: "2%",
                    xs: "5%",
                  },
                  display: "flex",
                  paddingTop: { lg: "5%", md: "7%", xs: "10%" },
                }}
              >
                <Box sx={{ paddingRight: "15px" }}>
                  <Divider
                    orientation="vertical"
                    width="11px"
                    sx={{
                      border: "solid 2px #0249ae",
                      backgroundColor: "#0249ae",
                      height: { lg: "17vh", md: "22vh", xs: "17vh" },
                    }}
                  />
                </Box>
                <Box>
                  <Box>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: {
                          xl: "50px",
                          lg: "50px",
                          md: "80px",
                          xs: "3.6rem",
                        },
                        fontWeight: "700",
                        lineHeight: "1.1em",
                        color: "#fff",
                      }}
                    >
                      Contact <br />
                      Us
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        paddingTop: "50px",
                        paddingBottom: "20px",
                        marginRight: {
                          xl: "20%",
                          lg: "0%",
                          md: "0%",
                          sm: "0%",
                          xs: "5%",
                        },
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "0%",
                          xs: "-5%",
                        },
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "20px",
                          fontWeight: "400",
                          color: "#fff",
                        }}
                      >
                        With strong beliefs in business transparency, economic,
                        legal, and ethical behavior practices, CEDG aligns with
                        Business Partners who share this philosophy and
                        commitment.
                      </Typography>
                      <Box sx={{ marginTop: "20px" }}>
                        <Paper sx={{ padding: "20px", width: "80%" }}>
                          <Grid container lg={12}>
                            {" "}
                            <Grid item lg={2} xs={2}>
                              <Avatar
                                src={phone}
                                sx={{ width: 30, height: 30 }}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={10}
                              xs={10}
                              sx={{
                                marginLeft: "-25px",
                                paddingLeft: { xs: "15px" },
                              }}
                            >
                              <Typography
                                variant="p"
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: "400",
                                  color: "#000",
                                }}
                              >
                                +1-757-452-3107
                              </Typography>
                            </Grid>
                            <Box sx={{ marginY: "20px" }} />
                            <Grid item lg={2} xs={2}>
                              <Avatar
                                src={fax}
                                sx={{ width: 30, height: 30 }}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={10}
                              xs={10}
                              sx={{
                                marginLeft: "-25px",
                                paddingLeft: { xs: "15px" },
                              }}
                            >
                              <Typography
                                variant="p"
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: "400",
                                  color: "#000",
                                }}
                              >
                                +1-757-452-3108
                              </Typography>
                            </Grid>
                            <Box sx={{ marginY: "20px" }} />
                            <Grid item lg={2} xs={2}>
                              <Avatar
                                src={mail}
                                sx={{ width: 30, height: 30 }}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={10}
                              xs={10}
                              sx={{
                                marginLeft: "-25px",
                                paddingLeft: { xs: "15px" },
                              }}
                            >
                              <Typography
                                variant="p"
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: "400",
                                  color: "#000",
                                }}
                              >
                                Glenn@cedg-global.com
                              </Typography>
                            </Grid>
                            <Box sx={{ marginY: "20px" }} />
                            <Grid item lg={2} xs={2}>
                              <Avatar
                                src={location}
                                sx={{ width: 30, height: 30 }}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={10}
                              xs={10}
                              sx={{
                                marginLeft: "-25px",
                                paddingLeft: { xs: "15px" },
                              }}
                            >
                              <Typography
                                variant="p"
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: "400",
                                  color: "#000",
                                }}
                              >
                                Capital Business Park, Building 2, Floor 6,
                                Sheikh Zaid, Egypt.{" "}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Paper
                sx={{
                  backgroundColor: "#ededed",
                  width: { lg: "20vw", xs: "80vw" },
                  marginLeft: { lg: "15%", xs: "10%" },
                }}
                elevation={7}
              >
                <Box
                  sx={{
                    paddingY: "50px",
                    marginLeft: { lg: "10%", xs: "20%" },
                  }}
                >
                  <Avatar
                    src={glenn}
                    sx={{
                      width: { lg: 250, xs: 200 },
                      height: { lg: 250, xs: 200 },
                    }}
                  />
                </Box>
                <Box sx={{ paddingBottom: "20px" }}>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: {
                        xl: "25px",
                        lg: "25px",
                        md: "80px",
                        xs: "2rem",
                      },
                      fontWeight: "700",
                      lineHeight: "1.1em",
                      color: "#000",
                    }}
                  >
                    Mr.Glenn Heard
                  </Typography>
                  <br />
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: {
                        xl: "20px",
                        lg: "25px",
                        md: "80px",
                        xs: "1.6rem",
                      },
                      fontWeight: "500",
                      lineHeight: "1.1em",
                      color: "#000",
                    }}
                  >
                    Chairman
                  </Typography>{" "}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
};

export default CSection;
